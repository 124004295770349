export const ENV_VARS = {
  LOG_LEVEL: "LOG_LEVEL",

  GTM_ID: "GTM_ID",
  COOKIEBOT_ID: "COOKIEBOT_ID",

  AUTH_ISSUER: "AUTH_ISSUER",
  AUTH_CLIENT_ID: "AUTH_CLIENT_ID",

  ADVISER_BASE_URL: "ADVISER_BASE_URL",
  ADVISER_PORTAL_FTRP_BASE_URL: "ADVISER_PORTAL_FTRP_BASE_URL",
  LOQATE_API_URL: "LOQATE_API_URL",

  ACCOUNT_APP_URL: "ACCOUNT_APP_URL",
  ACCOUNT_APP_REGISTRATION_PATH: "ACCOUNT_APP_REGISTRATION_PATH",

  ADVISER_EXTERNAL_URL: "ADVISER_EXTERNAL_URL",
  CHECK_IMMIGRATION_STATUS_URL: "CHECK_IMMIGRATION_STATUS_URL",
  ACCEPTABLE_PROPERTIES_GUIDE_URL: "ACCEPTABLE_PROPERTIES_GUIDE_URL",
  LIFETIME_ALLOWANCE_URL: "LIFETIME_ALLOWANCE_URL",

  NEW_ROOT_URL: "NEW_ROOT_URL"
};

export const getEnv = variableName => {
  const varKey = "REACT_APP_" + variableName;

  const getFromWindow = key => (window && window._env_ ? window._env_[key] : null);

  // Do not rewrite like process && process.env because it will lead to 'Process is not defined error'
  const getFromProcess = key => (process.env.NODE_ENV ? process.env[key] : null);

  return getFromWindow(varKey) ?? getFromProcess(varKey);
};

export default ENV_VARS;
