import "./site.css";

import React, { Suspense } from "react";
import ReactDOM from "react-dom";

import TagManager from "react-gtm-module/dist/TagManager";

import NotSupportedBrowserPage from "./components/not-supported-browser-page";
import TOGGLES from "./common/toggle-names";
import { ENV_VARS, getEnv } from "./common/env-variables";

const gtmId = getEnv(ENV_VARS.GTM_ID);
if (gtmId && window.Cookiebot?.consent?.statistics) {
  const tagManagerArgs = {
    gtmId
  };

  TagManager.initialize(tagManagerArgs);
}

function isUnsupportedBrowser() {
  const unsupportedBrowsers = ["MSIE", "Trident", "edge"];
  const userAgent = window.navigator.userAgent;

  for (let i = 0; i < unsupportedBrowsers.length; i++) {
    if (userAgent.indexOf(unsupportedBrowsers[i]) >= 0) {
      return true;
    }
  }

  return false;
}

const toggleValue = getEnv(TOGGLES.MAINTENANCE_ENABLED);
const isMaintenanceEnabled = toggleValue && toggleValue.toLowerCase() === "true";

if (isUnsupportedBrowser()) {
  ReactDOM.render(
    <React.StrictMode>
      <NotSupportedBrowserPage />
    </React.StrictMode>,
    document.getElementById("root")
  );
} else {
  if (isMaintenanceEnabled) {
    const MaintenancePage = React.lazy(function() {
      return import("./components/maintenance-page");
    });

    ReactDOM.render(
      <React.StrictMode>
        <Suspense fallback={null}>
          <MaintenancePage />
        </Suspense>
      </React.StrictMode>,
      document.getElementById("root")
    );
  } else {
    const App = React.lazy(function() {
      return import("./components/app");
    });

    ReactDOM.render(
      <React.StrictMode>
        <Suspense fallback={null}>
          <App />
        </Suspense>
      </React.StrictMode>,
      document.getElementById("root")
    );
  }
}
